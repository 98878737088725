export default [
    { name: 'ID', field: 'id' },
    { name: 'LEA', field: 'lea', initialHide: true, filter: false },
    {
        name: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        name: 'Update Date',
        field: 'updateDate',
        filter: 'date',
    },
    { name: 'Is Deleted', field: 'isDeleted', filter: false },
    {
        name: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    { name: 'Grading Period Name', field: 'gradingPeriodName', filter: false },
    {
        name: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    { name: 'Course Code', field: 'courseCode', filter: false },
    { name: 'Core Area', field: 'coreArea', filter: false },
    { name: 'Subject Area', field: 'subjectArea', filter: false },
    { name: 'Course Title', field: 'courseTitle', filter: false },
    { name: 'Credits', field: 'credits', filter: false },
    {
        name: 'Student Course Grade Letter',
        field: 'studentCourseGradeLetter',
        filter: false,
    },
    {
        name: 'Student Grade Percentage',
        field: 'studentGradePercentage',
        filter: false,
    },
    { name: 'Pass Indicator', field: 'passIndicator', filter: false },
    { name: 'Credits Attempted', field: 'creditsAttempted', filter: false },
    {
        name: 'Transfer District Name',
        field: 'transferDistrictName',
        filter: false,
    },
    { name: 'Transfer Site Name', field: 'transferSiteName', filter: false },
]
